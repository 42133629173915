import CloseIcon from "@mui/icons-material/Close"
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser"
import { Box, Button, IconButton, Link, Snackbar } from "@mui/material"
import * as Sentry from "@sentry/react"
import { GoogleAuthProvider, getAuth, getRedirectResult } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import GoogleImage from "../../../public/web_light_sq_SI@4x.png"
import { MainButton } from "../../components/mainButton"
import { TitleTextButton } from "../../components/titleTextButton"
import { logAnalyticsEvent } from "../../external/analytics"
import { signInWithGoogle } from "../../external/auth"
import { addCallDb } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { StateAction } from "../../types/State"
import {
  getBrowserDetails,
  isInAppBrowser,
  openInExternalBrowser,
} from "../../utils/func"
import { getDefaultAgentId } from "../../utils/general"
import { useAppContext, useAppDispatchContext } from "../Context"

interface UserInputProps {
  permissionsGranted: boolean
  capacity: boolean
  overUsage: boolean
  agentExists: boolean
}

export function UserInput({
  permissionsGranted,
  capacity,
  overUsage,
  agentExists,
}: UserInputProps) {
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const stream = context.stream
  const user = context.user
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  const { id } = useParams()
  const { pathname } = useLocation()

  const [errorMessage, setErrorMessage] = useState("")
  const [hasRedirected, setHasRedirected] = useState(false)

  const isInApp = isInAppBrowser()
  const [showInAppButton, setShowInAppButton] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const details = getBrowserDetails()
    Sentry.setContext("browser", {
      ...details,
      isInAppBrowser: isInApp,
      url: window.location.href,
      pathname: pathname,
    })
  }, [])

  useEffect(() => {
    const url = new URL(window.location.href)
    const wasRedirected = url.searchParams.get("redirected") === "true"

    if (wasRedirected) {
      url.searchParams.delete("redirected")
      window.history.replaceState({}, "", url.toString())
      setShowInAppButton(false)
      setHasRedirected(true)
      Sentry.addBreadcrumb({
        category: "inApp",
        message: "User was redirected back",
        level: "info",
        data: {
          ...getBrowserDetails(),
          isInAppBrowser: isInApp,
          url: window.location.href,
        },
      })
    } else {
      setShowInAppButton(isInApp)
      Sentry.addBreadcrumb({
        category: "inApp",
        message: `InApp button visibility set to: ${isInApp}`,
        level: "info",
        data: {
          ...getBrowserDetails(),
          isInAppBrowser: isInApp,
          url: window.location.href,
        },
      })
    }
  }, [isInApp])

  function isFormValid() {
    return stream !== null && user != null && permissionsGranted && agentExists
  }

  const handleExternalBrowserOpen = () => {
    if (!hasRedirected) {
      Sentry.captureMessage("InApp browser external open attempted", {
        level: "info",
        extra: {
          currentUrl: window.location.href,
          userAgent: navigator.userAgent,
          hasRedirected,
        },
      })
      openInExternalBrowser(window.location.href)
    }
  }

  const handleSignIn = async () => {
    setErrorMessage("")
    try {
      if (showInAppButton) {
        handleExternalBrowserOpen()
        return
      }
      const result = await signInWithGoogle()
      if (!result.success) {
        setErrorMessage(result.error)
      }
    } catch (error) {
      setErrorMessage(error.message)
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    const req = async () => {
      try {
        const result = await getRedirectResult(auth)
        if (result) {
          const credential = GoogleAuthProvider.credentialFromResult(result)
          const token = credential.accessToken
        }
      } catch (error) {
        setErrorMessage(error.code + " " + error.message)
        Sentry.captureException(error)
      }
    }
    req()
  }, [auth])

  const joinMeeting = async () => {
    const agentId = id || getDefaultAgentId
    if (agentExists) {
      const conversationId = await addCallDb(context.user, agentId)

      dispatch({
        type: StateAction.setCall,
        payload: {
          ...context.call,
          id: conversationId,
          agentId: agentId,
          chatMessages: [],
          startedAt: new Date(),
        },
      })

      context.stream.getTracks().forEach((track) => {
        track.stop()
      })

      logAnalyticsEvent(AnalyticsEvents.MeetingJoined)
      navigate(`/rooms/${conversationId}`)
    } else {
      setErrorMessage("Agent not found")
    }
  }

  const joinButton = (
    <MainButton
      onClick={joinMeeting}
      disabled={!isFormValid() || capacity || overUsage}
      text="Join Meeting"
    />
  )

  const signInButton = showInAppButton ? (
    <Button
      variant="contained"
      onClick={handleExternalBrowserOpen}
      startIcon={<OpenInBrowserIcon />}
      disabled={hasRedirected}
      sx={{
        backgroundColor: "#fff",
        color: "#000",
        border: "1px solid #dadce0",
        borderRadius: "4px",
        padding: "8px 16px",
        textTransform: "none",
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "#f8f9fa",
        },
      }}
    >
      {hasRedirected ? "Opening in Browser..." : "Open in Browser to Sign In"}
    </Button>
  ) : (
    <IconButton
      style={{
        backgroundColor: "transparent",
        padding: 0,
        display: "inline-block",
      }}
      onClick={handleSignIn}
    >
      <img
        src={GoogleImage}
        alt="Google Sign in"
        style={{ width: "183px", height: "40px" }}
      />
    </IconButton>
  )

  const tosLink = (
    <Link
      sx={{ textDecoration: "none", color: "#40069E" }}
      href="https://beyond-presence.notion.site/Terms-of-Use-86de0b8f3b60441bb829efa77a269e31"
    >
      Terms of Service
    </Link>
  )
  const privacyLink = (
    <Link
      sx={{ textDecoration: "none", color: "#40069E" }}
      href="https://beyond-presence.notion.site/Privacy-Policy-a17679fce58a48eea75761b5535f007a"
    >
      Privacy Policy
    </Link>
  )
  const joinText = (
    <>
      By clicking on "join meeting" you agree to our {tosLink} and {privacyLink}
      .
    </>
  )

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%",
        },
        px: { xs: 1, sm: 2, md: 3 },
        maxWidth: {
          xs: "100%",
          sm: "500px",
          md: "100%",
        },
        mx: "auto",
        overflow: "hidden",
      }}
    >
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setErrorMessage("")}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {user == null ? (
        <TitleTextButton
          title="Welcome 👋"
          text={<>Please sign in to join the meeting</>}
          button={signInButton}
        />
      ) : (
        <TitleTextButton
          title={
            overUsage
              ? "This agent is currently unavailable."
              : capacity
                ? "Oops! Our Agent is really busy right now."
                : !agentExists
                  ? "404: Agent not found"
                  : "Ready to join?"
          }
          subtitle={
            overUsage || capacity
              ? "Please try again later."
              : permissionsGranted
                ? ""
                : "Please allow microphone and camera to proceed."
          }
          text={joinText}
          button={joinButton}
        />
      )}
    </Box>
  )
}
