import { auth } from "../app/App"
import { Agent } from "../types/Agent"
import { Endpoints, Method, ReturnTypes, SecondPathParams } from "../types/Api"
import { Call } from "../types/Call"
import { UsageDetails } from "../types/UsageDetails"

export const isLocalDev = process.env.NODE_ENV === "development"

export const isDev = isLocalDev || window.location.href.includes("dev")

export const isStaging = window.location.href.includes("staging")

export const backendUrl = isLocalDev
  ? "http://127.0.0.1:8000/"
  : isDev
    ? "https://api-dev.bey.chat/"
    : isStaging
      ? "https://api-staging.bey.chat/"
      : "https://api.bey.chat/"

export interface VerifyTokenResponse {
  uid: string
  email: string
  name: string
  customerId?: string
  subscriptionState?: string
  subscriptionPlan?: string
  customToken?: string
}

const callApi = async (
  endpoint: Endpoints,
  method = Method.GET,
  id?: string,
  body?: any,
  secondPathParam?: SecondPathParams,
  returnType = ReturnTypes.json,
  token?: string,
) => {
  const idToken = await auth.currentUser?.getIdToken()
  const response = await fetch(
    secondPathParam && !id
      ? `${backendUrl}${endpoint}/${secondPathParam}`
      : secondPathParam
        ? `${backendUrl}${endpoint}/${id}/${secondPathParam}`
        : id
          ? `${backendUrl}${endpoint}/${id}`
          : `${backendUrl}${endpoint}`,
    {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token || idToken}`,
      },
    },
  )
  if (response.status !== 200) {
    const json = await response.json()
    throw new Error(`${response.status}: ${(json as any).detail}`)
  }
  if (returnType === ReturnTypes.blob) {
    return response.blob()
  } else {
    return response.json()
  }
}

export const verifyToken = async (
  token: string,
): Promise<VerifyTokenResponse> => {
  return callApi(
    Endpoints.creator,
    Method.POST,
    null,
    undefined,
    SecondPathParams.verifyToken,
    undefined,
    token,
  )
}

export const postOffer = async (body: any) => {
  return callApi(Endpoints.offer, Method.POST, undefined, body)
}

export const getVideo = async (id: string) => {
  return callApi(Endpoints.video, Method.GET, `${id}?${Date.now()}`)
}

export const getLivekitToken = async (room_id: string) => {
  return callApi(Endpoints.getToken, Method.POST, room_id, { room_id })
}

export const deleteLivekitRoom = async (room_id: string) => {
  return callApi(Endpoints.deleteRoom, Method.POST, room_id, { room_id })
}

export const getCapacity = async () => {
  return callApi(Endpoints.capacity, Method.GET)
}

export const getAgentUsage = async (
  agent_id: string,
): Promise<UsageDetails> => {
  return callApi(
    Endpoints.agent,
    Method.GET,
    agent_id,
    undefined,
    SecondPathParams.usage,
  )
}

export const getAgent = async (agent_id: string): Promise<Agent> => {
  return callApi(Endpoints.agent, Method.GET, agent_id)
}

export const getCall = async (call_id: string): Promise<Call> => {
  return callApi(Endpoints.call, Method.GET, call_id)
}

export const getDefaultAgentId = "defaultAgent"
