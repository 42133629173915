import * as Sentry from "@sentry/react"
import {
  GoogleAuthProvider,
  browserSessionPersistence,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  signInWithRedirect,
} from "firebase/auth"
import { auth } from "../app/App"
import { isDev, isLocalDev, isStaging, verifyToken } from "../utils/general"

const getCookieConfig = () => {
  const baseDomain = ".bey.chat"
  if (isLocalDev) {
    return {
      name: "__session_local",
      domain: "localhost",
    }
  }
  if (isDev) {
    return {
      name: "__session_dev",
      domain: baseDomain,
    }
  }
  if (isStaging) {
    return {
      name: "__session_staging",
      domain: baseDomain,
    }
  }
  return {
    name: "__session",
    domain: baseDomain,
  }
}

const setSessionCookie = (token: string | null) => {
  const { name, domain } = getCookieConfig()
  if (token) {
    document.cookie = `${name}=${token}; domain=${domain}; path=/; secure; samesite=Strict; max-age=604800`
  } else {
    document.cookie = `${name}=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=Strict`
  }
}

export const getSessionToken = async (forceRefresh = false) => {
  const user = auth.currentUser
  if (!user) return null

  try {
    return await user.getIdToken(forceRefresh)
  } catch (error) {
    Sentry.captureException(error)
    return null
  }
}

export const getSessionCookie = () => {
  const { name } = getCookieConfig()
  const cookies = document.cookie.split(";")
  const sessionCookie = cookies.find((cookie) =>
    cookie.trim().startsWith(`${name}=`),
  )
  return sessionCookie ? sessionCookie.split("=")[1].trim() : null
}

export const initializeAuth = async () => {
  try {
    await setPersistence(auth, browserSessionPersistence)
    const sessionToken = getSessionCookie()
    if (sessionToken && !auth.currentUser) {
      try {
        const userData = await verifyToken(sessionToken)

        if (!userData.customToken) {
          setSessionCookie(null)
          return null
        }
        try {
          const userCredential = await signInWithCustomToken(
            auth,
            userData.customToken,
          )
          return userCredential.user
        } catch (error) {
          console.error("error", error)
          Sentry.captureException(error)
          setSessionCookie(null)
          return null
        }
      } catch (error) {
        Sentry.captureException(error)
        console.error("error", error)
        setSessionCookie(null)
        return null
      }
    }
    return null
  } catch (error) {
    Sentry.captureException(error)
    console.error("Error setting auth persistence:", error)
    return null
  }
}

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider()
    await signInWithRedirect(auth, provider)
    return { success: true }
  } catch (error) {
    Sentry.captureException(error)
    return { success: false, error: error.message }
  }
}

export const signUserOut = async () => {
  try {
    setSessionCookie(null)
    await firebaseSignOut(auth)
    return { success: true }
  } catch (error) {
    Sentry.captureException(error)
    return {
      success: false,
      error: error instanceof Error ? error.message : "Unknown error",
    }
  }
}
export const initAuthObserver = (callback: (user: any) => void) => {
  return onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        const token = await user.getIdToken(true)
        const userData = await verifyToken(token)
        setSessionCookie(token)
        callback({ ...user, ...userData })
      } catch (error) {
        console.error("Error setting auth cookie:", error)
        Sentry.captureException(error)
        callback(null)
      }
    } else {
      callback(null)
    }
  })
}
