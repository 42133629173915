export enum Method {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum ReturnTypes {
  json = "json",
  blob = "blob",
}

export enum Endpoints {
  offer = "offer",
  call = "call",
  video = "video",
  getToken = "getToken",
  deleteRoom = "deleteRoom",
  agent = "agent",
  capacity = "capacity",
  creator = "creator",
}

export enum SecondPathParams {
  usage = "usage",
  verifyToken = "verify-token",
}
